@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

.action-modal {
    &-alert {
        background-color: rgba(188, 0, 0, .06);
        color: #BC0000;
        padding: .5rem 1rem;
        margin-bottom: 1rem;
        font-weight: 300;

        b {
            font-weight: 500;
        }

        i {
            margin-right: 1rem;
        }
    }

    &-options {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        @include for-phone-only {
            display: block;
        }

        > div {
            flex: 1;
            flex-basis: 100%;
            @include for-tablet-portrait-up {
                flex-basis: 50%;
            }
            @include for-tablet-landscape-up {
                flex-basis: 25%;
            }
        }
    }

    &-option {
        cursor: pointer;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        &-icon {
            @include full-center;
            padding: 40px 0;

            i {
                opacity: .5;
                font-size: 80px;
                color: $color-primary;
                transition: 0.4s ease-in-out all;
            }
        }

        .button {
            opacity: .5;
            justify-content: center;
            width: 100%;
        }

        > div:first-child {
            flex: 1;
        }

        &:hover {
            i, .button {
                opacity: 1 !important;
            }
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
    }

    &-step2 {
        align-items: center;
        display: flex;
        margin-top: 20px;
        margin-bottom: 50px;
        @include for-phone-only {
            flex-direction: column;
            justify-content: center;
        }

        .acms-icon {
            padding: 0 64px 0 32px;
            @include for-phone-only {
                padding: 0;
            }

            i {
                font-size: 80px;
                color: #5a603e;
            }
        }

        > div:last-child {
            border-left: solid 1px #f0f0f0;
            padding-left: 64px;
            flex: 1;
            @include for-phone-only {
                border: 0;
                padding-left: 0;
            }
        }

        .acms-steps {
            display: flex;
            margin: 0 auto 32px auto;
            max-width: 277px;
            justify-content: space-between;
            position: relative;

            &:after {
                content: '';
                background: #F0F0F0;
                height: 1px;
                position: absolute;
                width: 100%;
                top: 50%;
                left: 0;
                z-index: 1;
            }

            > div {
                z-index: 2;
                border-radius: 100%;
                position: relative;
                @include full-center;
                height: 55px;
                background: rgba(240, 240, 240, .5);
                color: rgba(67, 67, 67, .5);
                width: 55px;
                border: solid 10px #ffffff;

                &.active {
                    background: #979F73;
                    color: #ffffff;
                }
            }
        }

        .acms-dropdown {
            margin: 0 auto;
            max-width: 200px;

            textarea {
                background: #F8F8F8;
                border-color: #f0f0f0;
                min-height: 125px;
            }

            p > span {
                display: inline-block;
                margin: 0 8px;
                background: #f0f0f0;
                padding: 5px 16px;
                border-radius: 3px;

                i {
                    margin-right: 10px;
                }
            }
        }
    }
}
