.modal-v2 {
    &-wrapper {
        background-color: #ffffff;
        border: solid 1px #f0f0f0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    }
    border-radius: 3px;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 420px;
    position: fixed;
    z-index: 3001;

    &--large {
        @media screen and (min-width: 992px) {
            width: 992px;
        }
        @media screen and (min-width: 1200px) {
            width: 1200px;
        }
    }

    &-overlay {
        background-color: rgba(0, 0, 0, .2);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        overflow: auto;
        width: 100vw;
        z-index: 3000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-header {
        align-items: center;
        border-bottom: solid 1px #f0f0f0;
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;

        > span {
            font-family: 'Poppins', sans-serif;
            line-height: 20px;
            font-size: 16px;
            color: #434343;
            font-weight: 500;
        }

        > i {
            cursor: pointer;
            font-size: 24px;
        }
    }

    &-body {
        padding: 24px 16px 0 16px;
        position: relative;
        max-height: 77vh;
        overflow: auto;

        &-fill {
            min-height: 77vh;
        }
        
        .modal-v2-text {
            font-size: 16px;
            font-weight: 100;
            line-height: 25px;
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 16px 32px 16px;
    }
}

.on-top {
    z-index: 10001;
}
