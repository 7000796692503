@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.reservations-view {
	&-calendar {
		flex: 1;
		margin-top: 20px;
	}

	.pause-btn {
		@include for-tablet-under {
			display: none;
		}
	}

	&-cd {
		@include for-desktop-up {
			display: flex;
		}
		--fc-border-color: #efefef;

		.fc {
			background: #ffffff;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, .09);
		}

		.list {
			margin-top: 20px;
			@include for-desktop-up {
				margin-left: 2rem;
			}
			min-width: 340px;
			overflow: auto;

			.list-items {
				box-shadow: 0px 3px 6px rgba(0, 0, 0, .09);
				background: #fff;
				padding-top: .5rem;
			}
		}
	}

	&-mobile-head {
		flex: 1;
		// overflow: hidden;
		@include for-tablet-portrait-up {
			display: flex;
			align-items: flex-start;

			> div {
				@include for-desktop-up {
					flex: 1;
				}
			}

			> div:last-child {
				margin-left: 16px;
			}
		}
	}

	&-head {
		display: flex;
		align-items: center;
		margin: 20px 0 0 0;
		justify-content: space-between;
		@include for-tablet-under {
			background-color: #f8f8f8;
			// flex-wrap: wrap;
			position: sticky;
			top: 66px;
			z-index: 99;
			padding: 10px 0 0 0;
			// background: #fff;
			// margin-top: 10px;
		}

		.full-buttons {
			// @include for-tablet-landscape-under {
			display: none;
			@include for-desktop-up {
				display: flex;
			}
		}
		.mobile-buttons {
			display: block;
			flex: 1;
			// @include for-tablet-landscape-under {
			@include for-desktop-up {
				display: none;
			}
		}

		> div {
			display: none;
			align-items: center;

			// @include for-tablet-under {
			@include for-desktop-up {
				display: flex;
			}

			&:first-child {
				flex: 1;
			}
		}

		&-switch {
			box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
			margin-right: 1rem;
			.button {
				margin: 0 !important;
				&.active {
					background-color: #e5e5e5 !important;
					color: #5A603E;
				}

				i {
					font-size: 22px;
				}
			}
		}

		&-search {
			position: relative;
			border-radius: 3px;
			@include for-tablet-landscape-up {
				min-width: 320px;
				max-width: 320px;
				margin-right: 1rem;
			}
			width: 100%;
			@include for-phone-only {
				width: calc(100vw - 4rem - 48px);
			}

			i {
				color: $color-primary;
				position: absolute;
				top: 50%;
				font-size: 1rem;
				transform: translateY(-50%);
				left: 1rem;
			}

			input {
				border: 0;
				padding-left: 3rem;
			}

			.input {
				border-radius: 3px;
				box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
				width: 100% !important;
				@include for-phone-only {
					// margin: 10px 0 0 0;
				}
			}
		}

		.button {
			margin-left: 1rem;
		}
		.reload-button {
			// margin: 0;
			display: flex;
			align-items: center;
		}
		.date-input {
			.react-datepicker-wrapper {
				@include for-tablet-under {
					width: 100%;
				}
			}
		}
	}
	.timeslot-form {
		padding: 0;
		width: 100%;
		max-width: 100% !important;
	}
}

.reservations-list {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	&-item {
		padding: 15px;
		border-bottom: 1px solid $color-gray;

		p,
		strong {
			display: block;
		}
		.info-label {
			min-width: 200px;
			display: inline-block;
		}
	}
}

.mobile-table {
	display: none;
	margin-top: 20px;
	@include for-tablet-under {
		background: #ffffff;
		display: flex !important;
		flex-direction: column;
	}
}

.reservation-table {
	border: solid 1px #f0f0f0;
	@include for-tablet-under {
		display: none !important;
	}
	&-empty {
		@include full-center();
		flex: 1;
		width: 100%;
	}

	.small-date {
		opacity: .5;
		display: block;
	}

	.actions {
		display: flex;
		.icon {
			margin-left: 10px;
			font-size: 16px;
			color: $color-gray-dark;
			transition: 0.4s ease;
			cursor: pointer;
			img {
				height: 18px;
			}
			span i:first-child {
				font-size: 36px;
			}
			span i:last-child {
				font-size: 16px;
				position: relative;
				top: 0px;
			}

			&.blue:hover { color: $color-blue; }
			&.yellow:hover { color: $color-yellow; }
			&.red:hover { color: $color-red; }

			&:first-child {
				margin-left: 0;
			}
		}
	}
	thead {
		// background: darken($color-gray, 10);
		background: #fff;
		th {
			background: #fff;
			font-weight: 500;
			font-family: "Poppins", sans-serif;
			border-bottom: solid 3px #f8f8f8;
			padding: 12px;
			
			&:first-child {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
			&:last-child {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}
	}
	tbody {
		tr {
			td {
				background: #fff;
				border-bottom: 1px solid #f0f0f0;
				font-family: "Poppins", sans-serif;
				font-weight: 300;
				padding: 12px;

				&:first-child {
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}
				&:last-child {
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
				}

				&.collapsing {
					@include for-tablet-up {
						white-space: nowrap;
                    	width: 1px;
					}
				}
			}

			&:first-child {
				td {
					border-top: 1px solid #f0f0f0;
				}
			}
		}
	}
	.for-mobile {
		@include for-tablet-landscape-under {
			display: table-cell !important;
		}
	}
	td,
	th {
		@include for-tablet-landscape-under {
			display: none !important;
		}
	}
}

.row-status {
	display: inline-block;
	border-radius: 3px;
	background-color: #F0F0F0;
	color: #434343;
	padding: 6px 10px;
	font-size: 14px;
	font-weight: 300;
	margin-right: 10px;
	
	&-new {
		display: none;
	}
	&-edited {
		color: #e5b900;
		background-color: rgba(229, 185, 0, .05);
		text-transform: uppercase;
	}
	&-cancelled, &-action {
		color: #bc0000;
		background-color: rgba(188, 0, 0, .05);
		text-transform: uppercase;
	}
}

.row-status + .row-status {
	margin-top: 2px;
}

.reservation-edit {
	.modal-body_content {
		display: flex;
	}
	.form-group {
		margin-bottom: 20px;
	}
	.person-slider {
		margin-bottom: 0;
	}
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
	background-color: $color-primary;
}

.react-datepicker__header {
	background-color: $color-secondary;
	* { color: #ffffff !important; }
}
.react-datepicker__navigation--next {
	border-left-color: #ffffff !important;
}
.react-datepicker__navigation--previous {
	border-right-color: #ffffff !important;
}


.rvc-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	.rvc-switch {
		margin-right: 0;
	}

	.rvc-title {
		span {
			margin: 0 1rem;
		}

		.button {
			background: transparent !important;
			color: #A8A8A8;

			&:hover {
				background: rgba(168, 168, 168, .15) !important;
			}
		}
	}
}

.fc-header-toolbar {
	display: none !important;
}

.fc-col-header-cell {
	font-weight: 300 !important;
	color: #434343 !important;
	font-size: 14px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	text-transform: capitalize;
}

.fc-daygrid-day {
	padding: 5px !important;
}

.fc-event-title {
	// background-color: $color-blue !important;
	font-size: 14px !important;
	font-weight: 300 !important;
	height: 2rem !important;
	display: flex !important;
	align-items: center !important;
	border-radius: 3px !important;
	padding: 0 .5rem !important;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: rgba(151, 159, 115, .15) !important;
}

.list-item {
	padding-bottom: 1rem;
	margin: 1rem;
	border-bottom: 1px solid #f0f0f0;
	display: flex;
	align-items: center;
	color: #434343;
	cursor: pointer;
	

	&-count {
		border-left: solid 5px $color-secondary;
		padding-left: 5px;
		margin-right: 16px;

		&-edited {
			border-color: #e5b900;
		}
		&-cancelled, &-action {
			border-color: #bc0000;
		}

		span {
			@include full-center;
			background: #f0f0f0;
			margin-bottom: 1px;
			border-radius: 2px;
			height: 30px;
			font-size: 14px;
			font-weight: 500;
			width: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-info {
		font-weight: 300;
		flex: 1;

		.row-status {
			background-color: transparent;
			padding: 0;
			font-size: 14px;
		}

		p {
			margin: 0 0 5px 0;
			display: flex;
			justify-content: space-between;

			&:last-child {
				padding-top: 3px;
			}
		}
	}
}

.mdpi {
	display: block;
	max-width: 260px;
	@include for-desktop-up {
		display: none;
	}
}

.rvc-switch {
	display: block;
	@include for-desktop-up {
		display: none;
	}
}

.rvc-mob-btn {
	display: none;
	margin-right: 16px;
	@include for-tablet-portrait-up {
		display: block;
	}
	@include for-desktop-up {
		display: none;
	}

	.button {
		margin: 0;
	}
}
