@import "./vars.scss";
@import "./mixins.scss";
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

html,
body {
	min-height: 100vh;
	scroll-behavior: smooth;
}

#root {
	min-height: 100vh;
	background: $color-background;
	display: flex;
	flex-direction: column;
}

.container-center {
	@include full-center();
	flex: 1;
}

main {
	flex: 1;
	max-width: 100vw;
	padding-bottom: 40px;
}

.hide-on-desktop {
	@include for-tablet-up {
		display: none !important;
	}
}

.container {
	display: block;
	margin: 0 auto;
	width: 100%;
	padding: 0 20px;
	max-width: 1180px;
	&-small {
		max-width: 880px;
		display: block;
		margin: 0 auto;
		width: 100%;
		padding: 0 20px;
	}
}

section {
	padding: 80px 0;
}

.info-row {
	display: flex;
	div:first-child {
		margin-right: 10px;
	}
	.large {
		flex: 1;
	}
	.dropdown {
		width: max-content;
	}
}

.slick-arrow {
	&::before {
		font-size: 32px !important;
		color: $color-primary !important;
		font-family: "Font Awesome 5 Pro" !important;
	}
}

.slick-prev,
.slick-next {
	// width: 30px;
	height: 30px;
}

.slick-prev {
	left: -10px !important;
	&::before {
		content: "\f104" !important;
	}
}
.slick-next {
	right: -10px !important;
	&::before {
		content: "\f105" !important;
	}
}

.arrows {
	display: flex;
	.arrow {
		color: $color-gray-dark;
		border: 1px solid $color-gray-dark;
		@include full-center();
		@include circle(40px);
		cursor: pointer;
		font-size: 24px;
		margin-right: 10px;
		&:hover {
			background: $color-gray;
		}
		&:last-child {
			margin: 0;
		}
	}
}

.full-center {
	@include full-center();
}

.tooltip {
    background-color: $color-primary;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .05);
    color: #ffffff;
	font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 100;
    left: 50%;
    // width: 350px;
    padding: 8px 16px;
    pointer-events: none;
    position: absolute;
    opacity: 0;
    text-transform: none;
    bottom: 15px;
    transform: translateX(-50%);
    transition: all 150ms ease-out;
	white-space: nowrap;
    z-index: 2;
    
    &:after {
        border: solid 5px transparent;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: $color-primary;
        content: '';
        height: 0;
        margin-left: -4px;
        left: 50%;
        position: absolute;
        pointer-events: none;
        top: 100%;
        width: 0;
    }
}
