@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
@import "react-datepicker/dist/react-datepicker";

.input-gray {
	input,
	textarea {
		background: $color-gray;
	}
}

.input {
	display: flex;
	flex-direction: column;
	&-error {
		input,
		textarea {
			border: 1px solid #d63031 !important;
		}
	}
	&-increments-error {
		.increments {
			border: 1px solid #d63031 !important;
		}
	}
	.MuiInput-root {
		&:hover {
			&::before {
				border: 0px;
			}
		}
		&::before,
		&::after {
			content: "" !important;
			display: none;
			border: 0px solid transparent;
		}
	}

	&-row {
		display: flex;
		input {
			border-radius: 5px 0 0 5px !important;
			flex: 1 1 auto !important;
		}
	}
	label {
		margin-bottom: 5px;
		font-size: 14px;
		color: #433;
		line-height: 18px;
		.optional {
			color: #e6e6e6;
		}
	}
	textarea {
		min-height: 200px;
		font-family: "Poppins", sans-serif !important;
		padding: 1rem !important;
	}
	input {
		min-height: $input-height !important;
	}
	input[type="time"]:invalid {
		border: 1px solid #d63031 !important;
	}
	input,
	textarea,
	.date-picker-input {
		display: block;
		box-sizing: border-box !important;
		padding: 10px;
		border-radius: 5px;
		border: 1px solid #EAE9EA;//$color-gray-dark;
		color: #434343;
		font-size: 14px;
		font-family: "Poppins", sans-serif !important;
		font-weight: 400 !important;

		&:focus {
			outline: none;
			border-color: $color-primary;
		}
		&::placeholder {
			color: $color-gray-dark;
			@include placeholder();
		}
	}
	.date-picker {
		height: 45px;
	}
	.date-picker-input {
		height: 45px;
		min-width: 140px;
		cursor: pointer;
		.placeholder {
			color: $color-gray-dark;
			@include placeholder();
		}
	}
	.rc-time-picker {
		width: 120px;
		&-clear {
			display: flex;
			height: auto !important;
			top: 0;
			bottom: 0;
			align-items: center;
		}
	}

	&.date-input-disabled span.value {
		color: rgba(0, 0, 0, .2) !important;
	}

	&-time {
		position: relative;
		
		&-preview {
			display: flex;
			// position: absolute;
			z-index: 2;
			pointer-events: none;

			span {
				display: block;
				user-select: none;
				height: $input-height;
				width: $input-height;
				cursor: pointer;
				border-radius: 3px;
				color: #434343;
				background: #f0f0f0;
				@include full-center();
				&:first-child {
					margin-right: 10px;
				}
			}
		}

		.rc-time-picker {
			opacity: 0;
			position: absolute;
			top: 0;
		}
	}
}

.rc-time-picker-panel {
	z-index: 5001;
}

.rc-time-picker-panel-inner {
	width: 122px;
}
.rc-time-picker-panel-select {
	width: 60px;
}
.rc-time-picker-panel-input-wrap {
	height: 45px;
	padding: 0;
	border-radius: 5px;
	width: 120px;
	max-width: 120px;
	input {
		border-radius: 5px;
		height: 45px;
		display: block;
		box-sizing: border-box !important;
		padding: 10px;
		color: #434343;
		font-size: 14px;
		font-family: "Poppins", sans-serif !important;
		font-weight: 400 !important;
		// padding: 10px;
	}
}

.input-button {
	display: flex;
	border: 0px;
	border-radius: 0 5px 5px 0;
	align-items: center;
	background-color: $color-primary;
	color: $color-background;
	padding: 0 20px;
	cursor: pointer;
	font-weight: 600;
	&-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&:focus {
		outline: none;
	}
	.icon {
		padding-left: 10px;
		i {
			color: $color-background;
		}
	}
}

.increments {
	display: flex;

	&-icon {
		user-select: none;
		height: $input-height;
		width: $input-height;
		cursor: pointer;
		border-radius: 3px;
		color: #434343;
		background: rgba(240, 240, 240, .31);
		@include full-center();
		&:hover {
			background: #f0f0f0;
		}
	}

	input {
		border: 1px solid #EAE9EA;//$color-gray-dark;
		border-radius: 3px;
		width: 60px;
		text-align: center;
		margin: 0 16px;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
