@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.register-info {
	padding: 16px;
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	background-color: rgba(255, 255, 255, .3);
	
	> div:first-child {
		margin-right: 16px;
		font-size: 32px;
		color: #5A603E;
	}

	p {
		color: #434343 !important;
		font-size: 14px !important;
	}
}

.register-grid {
	margin: 0 auto;
	max-width: 490px;

	&-title {
		display: block;
		text-align: center;
		font-size: 22px;
	}
	.card {
		background-color: transparent;
		&-info {
			@include for-phone-only {
				padding: 0;
			}
		}
	}
	.info-box {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
	}

	.form {
		margin: 20px 0;
		.pagination {
			color: #fff;
			opacity: 0.3;
		}
		.row {
			@include for-phone-only {
				flex-direction: column;
				.input {
					width: 100%;
				}
			}
		}
		&-fields {
			margin-top: 20px;
		}
		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			padding-bottom: 20px;
			.button-transparent {
				color: #fff;
				font-weight: 100;
				i {
					font-size: 24px;
				}
			}
		}
		.login-cta {
			padding: 20px 0;
			@include full-center();
			color: #fff;
			p {
				margin: 0;
			}
			.button {
				color: #fff;
				height: 30px !important;
			}
		}
		.agree-cta {
			padding: 20px 0;
			@include full-center();
			color: #fff;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			text-align: center;
			a {
				text-decoration: none;
			}
		}
	}
	.info-account {
		margin-top: 30px;
	}

	.row {
		display: flex;
		.input {
			margin-right: 15px;
			width: auto;
			input {
				width: 100%;
			}
			&:last-child {
				margin: 0;
			}
		}
		.dropdown {
			width: auto;
		}
	}
	.full {
		flex: 1;
	}
	input,
	.dropdown {
		margin-bottom: 15px;
	}

	.disclaimer {
		margin: 10px 0 10px;
		font-size: 12px;
	}
}

.ws {
	max-width: 520px;

	.buttons {
		justify-content: center !important;
		border: 0 !important;
		margin-top: 48px !important;
		@include for-phone-only {
			display: block !important;

			a {
				margin: 5px 0 0 0 !important;
				width: 100%;
			}
		}
	}
}

.register-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	-webkit-flex: 0 1 100%;
	min-height: 100vh;
	background: $color-secondary;
	.container-small {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.styled-text,
	a:not(.button) {
		color: #fff;
	}
	&-loading {
		color: #fff;
		flex: 1;
		@include full-center();
		height: 100vh;
		p { opacity: 1 !important; }
	}

	.acms-steps {
		display: flex;
		margin: 48px auto 16px auto;
		max-width: 277px;
		justify-content: space-between;
		position: relative;

		&:after {
			content: '';
			background: #F0F0F0;
			height: 1px;
			position: absolute;
			width: 100%;
			top: 50%;
			left: 0;
			z-index: 1;
		}

		> div {
			z-index: 2;
			border-radius: 100%;
			position: relative;
			@include full-center;
			height: 55px;
			background: rgba(203, 207, 185, 1);
			color: rgba(90, 96, 62, .5);
			width: 55px;
			border: solid 10px $color-secondary;

			&.active {
				background: #5A603E;
				color: #ffffff;
			}
		}
	}
}
