@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.layout-no-line {
	.layout-head {
		border-bottom: 0px !important;
	}
}

.layout-mobile-button {
	display: none;
	@include for-phone-only {
		display: flex;
		margin-bottom: 20px;
	}
}

.layout-admin {
	display: flex;
	flex: 1;

	main {
		padding: 60px;
		@include for-tablet-under {
			padding: 40px 20px;
		}
		display: flex;
		flex-direction: column;
		background: $color-background-admin;
	}
	&-loading {
		@include full-center();
		flex: 1;
	}
	.layout-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;
		// margin-bottom: 20px;
		padding-bottom: 10px;
		@include for-tablet-under {
			display: none;
		}
		&:empty {
			display: none;
		}
	}
	.layout-title {
		font-size: 24px;
		display: flex;
		font-weight: 500;
		color: #433;

		@include for-tablet-under {
			display: none;
		}
		.icon {
			cursor: pointer;
			margin-right: 10px;
		}
	}
}

.layout {
	padding-top: $nav-height;
	display: flex;
	flex-direction: column;
	flex: 1;
	@include for-phone-only {
		padding-top: $nav-height-mobile;
	}
	&-loading {
		@include full-center();
		width: 100%;
		height: calc(100vh - #{$nav-height});
	}
}

.layout-admin-mobile {
	padding: 90px 20px 110px;
	position: relative;

	.top-bar {
		align-items: center;
		background-color: #ffffff;
		border-bottom: solid 1px #eae9ea;
		display: flex;
		justify-content: space-between;
		left: 0;
		position: fixed;
		height: 66px;
		top: 0;
		width: 100%;
		z-index: 2001;

		> span {
			font-family: 'Poppins', sans-serif;
			font-weight: 300;
			font-size: 16px;
			color: #434343;
			position: absolute;
			z-index: 1;
			width: 100%;
			text-align: center;
		}

		&-title {
			justify-content: flex-start;

			> span {
				display: block;
				padding: 0 20px;
				font-weight: 500;
				font-size: 20px;
				text-align: left;
			}
		}

		&-icon {
			align-items: center;
			color: #434343;
			display: flex;
			height: 66px;
			padding: 0 20px;
			position: relative;
			text-decoration: none;
			z-index: 2;

			i {
				font-size: 20px;
			}
		}
	}

	.tab-bar {
		background-color: #ffffff;
		border-top: solid 1px #eae9ea;
		bottom: 0;
		display: flex;
		left: 0;
		position: fixed;
		height: 90px;
		width: 100%;
		z-index: 2001;

		&-changes {
			background: #ffffff;
			align-items: center;
			display: flex;
			bottom: 0;
			left: 0;
			height: 89px;
			padding: 0 20px;
			width: 100%;
			position: fixed;
			transform: translateY(89px);
			opacity: 0;
			transition: transform 200ms cubic-bezier(0.37, 0, 0.63, 1), opacity 100ms linear;
			z-index: 2002;

			&-in {
				transform: translateY(0);
				opacity: 1;
			}

			button {
				margin-top: -10px !important;
				&:first-child {
					margin-right: 10px;
				}
				&:last-child {
					margin-left: 10px;
				}
			}
		}

		.tab-select {
			height: 1px;
			position: absolute;
			transform: translateX(0%);
			transition: transform 350ms ease-in-out;
			top: -1px;
			width: 100%;

			> div {
				background-color: #979f73;
				height: 2px;
				width: 20%;
			}
		}

		.tab-bar-item {
			align-items: center;
			border-top: solid 1px transparent;
			display: flex;
			flex-basis: 20%;
			height: 90px;
			justify-content: center;
			text-decoration: none;
			position: relative;

			span {
				background-color: #BC0000;
				display: inline-block;
				color: #fff;
				border-radius: 3px;
				padding: 1px 7px;
				font-size: 10px;
				position: absolute;
				top: 23px;
				right: 12px;
			}

			i {
				color: #a8a8a8;
				font-size: 24px;
			}

			> div {
				align-items: center;
				background-color: #5a603e;
				border-radius: 100%;
				box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
				display: flex;
				height: 42px;
				justify-content: center;
				width: 42px;
				transform: rotate(0);
				transition: transform 200ms ease-in-out;

				&.rotate {
					transform: rotate(225deg);
				}

				i {
					color: #ffffff;
				}
			}

			&.is-active {
				// border-color: #979f73;
				i {
					color: #979f73;
				}
			}
		}
	}
}

.timeslot-form-empty,
.timeslot-form-success {
	@include full-center();
	padding: 40px;
	flex: 1;
	i {
		display: block;
		font-size: 50px;
		margin-bottom: 10px;
	}
	h1 {
		text-align: center;
	}
	p {
		display: block;
		text-align: center;
	}
	.button {
		margin-top: 10px;
		text-align: center;
	}
}

.tfsf {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background: rgba(255, 255, 255, .95);
	opacity: 0;
	visibility: hidden;
	transition: opacity 200ms linear, visibility 0s linear 200ms;

	&-show {
		opacity: 1;
		transition-delay: 0;
		visibility: visible;;
	}
}
