@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.restaurant-card {
	transition: 0.4s ease transform;
	flex-direction: column;
	text-decoration: none;
	display: flex;
	justify-content: flex-start;
	flex: 1;
	height: 100%;
	@include for-tablet-under {
		margin-bottom: 20px;
	}

	.card {
		box-shadow: 0px 0px 0px transparent;
		flex: 1;
		display: flex;
		flex-direction: column;
		&-info {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
	&-wrapper {
		.card-info {
			padding: 0 !important;
		}
	}
	&:hover {
		transform: scale(1.02);
	}
	&-info {
		padding: 20px;
		flex: 1;
		display: flex;
		flex-direction: column;
		&-category {
			text-transform: uppercase;
		}
	}
	.description {
		margin-top: 10px;
	}
	.styled-text,
	.badge {
		text-decoration: none;
	}
	.badge {
		border-radius: 3px !important;
	}
	.badges {
		margin-bottom: 10px;
	}
	.address {
		// opacity: 0.2;
		display: block;
		max-height: 46px;
		line-height: 23px;
		color: $color-text;
	}
	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.name {
		font-size: 18px;
	}
	strong {
		display: block;
		color: $color-text;
		// margin-bottom: 6px;
	}

	&-places {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #f0f0f0;
		border-radius: 0;
		.count {
			background-color: $color-primary;
			border-radius: 8px;
			width: 32px;
			height: 32px;

			@include full-center();
			&-disable {
				background: $color-text;
			}
			strong {
				color: #fff;
			}
		}
	}
}
