@import '../../../styles/mixins.scss';

body {
    overflow-x: hidden;
}

.for-partners {
    padding-top: 70px;

    .row {
        box-sizing: content-box;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin: 70px -2rem 0 -2rem;
        padding-bottom: 140px;
        position: relative;
        @include for-tablet-under {
            margin: 35px -1rem 0 -1rem;
            padding-bottom: 70px;
            flex-direction: column;
        }

        &.center {
            justify-content: center;

            .col {
                text-align: center;
                max-width: 1040px;
            }

            form {
                align-items: center;
                display: flex;
                @include for-tablet-under {
                    display: block;
                }

                .input {
                    flex: 1;
                }

                .button {
                    font-size: 16px !important;
                    margin-left: 1rem;
                    @include for-tablet-under {
                        display: block;
                        margin-left: 0;
                        margin-top: .5rem;
                        width: 100% !important;
                    }
                }
            }
        }

        &-alt {
            background: #f0f0f0;
        }

        &-bg {
            margin-top: 0;
            padding-top: 70px;
            padding-bottom: 70px;
            @include for-tablet-under {
                margin-top: 0;
                padding-top: 35px;
                padding-bottom: 35px;
            }
        }
    }

    .col {
        padding: 0 2rem;
        color: #434343;
        @include for-tablet-under {
            padding: 0 1rem;
        }
        
        h1, h2 {
            color: #434343;
            font-size: 45px;
            line-height: 1.5;
            margin: 0;
            font-weight: 500;
            margin-bottom: 40px;
            @include for-tablet-under {
                font-size: 36px;
                line-height: 44px;
                text-align: center;
            }
        }

        p {
            color: #434343;
            font-weight: 300;
            font-size: 20px;
            line-height: 1.5;
            margin-bottom: 40px;
            @include for-tablet-under {
                font-size: 18px;
                line-height: 1.4;
                text-align: center;
            }
        }

        .button {
            height: auto;
            font-size: 24px;
            font-weight: 300 !important;
            padding: 12px 2.5rem;
            border-radius: 6px;

            &-black {
                background: #000;
            }
            &-orange {
                background: #E3773B;
            }
        }
    }

    .col-text {
        flex-basis: 45%;
        position: relative;
        z-index: 2;
        @include for-tablet-under {
            order: 1;
            margin-bottom: 30px;
        }

        .text {
            padding-right: 50px;
            @include for-tablet-under {
                padding-right: 0;
            }

            h2 {
                font-size: 28px;
                line-height: 1.3;
                margin-bottom: 30px;
            }

            p {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        &.mob {
            margin-top: 30px;
            display: none;
        }

        .button {
            font-size: 16px !important;
        }

        @include for-tablet-under {
            display: none;
            flex-direction: column;
            &.mob {
                display: flex;
            }

            .button {
                margin: 0 0 10px 0;
                width: 100%;
                text-align: center !important;
                justify-content: center;
            }
        }
    }

    .col-image {
        flex-basis: 55%;
        position: relative;
        @include for-tablet-under {
            order: 2;
        }

        .bg {
            left: 100px;
            position: absolute;
            top: -50px;
            z-index: 1;

            &-left {
                left: -100px;
            }
        }

        .image {
            border-radius: 6px;
            position: relative;
            z-index: 2;

            @include for-tablet-under {
                top: 0 !important;
            }
            
            &-shadow {
                box-shadow: 0 3px 10px rgba(0, 0, 0, .15);
            }

            > img {
                border-radius: 6px;
                display: block;
                max-width: 100%;
                margin: 0;
            }
        }
    }

    .pic-5 {
        h1, h2 {
            font-size: 36px;
            line-height: 1.5;
            margin-bottom: 20px;
            @include for-tablet-under {
                font-size: 20px;
                line-height: 1.4;
            }
        }

        p {
            font-size: 20px;
            line-height: 1.5;
            margin-bottom: 40px;
            @include for-tablet-under {
                font-size: 16px;
                line-height: 1.4;
            }
        }

        .image {
            @include for-tablet-up {
                position: absolute;
                top: -170px;
            }
        }
    }
}

.icon-list {
    display: flex;

    > ul {
        flex: 1;
        margin: 0 0 40px 0;
        padding: 0;
        list-style: none;

        &:first-child {
            padding-right: 32px;
        }

        li {
            padding-left: 28px;
            position: relative;

            i {
                color: #E3773B;
                position: absolute;
                left: 0;
                top: 6px;
            }
        }
    }
}

.embed {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    iframe {
        border: 0;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.half-page {
    position: relative;
    border: solid 1px transparent;

    &-bg {
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
        @include for-tablet-under {
            display: none;
        }

        > div {
            flex: 1;

            &:first-child {
                background: #f0f0f0;
            }
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }
}

.reg-form {
    h2 {
        text-align: center;
        margin: 0;
    }

    &-steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 230px;
        margin: 16px auto;
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            background: #f0f0f0;
            top: 50%;
            transform: translateY(-50%);
        }

        .step {
            border-radius: 100%;
            @include full-center;
            height: 49px;
            font-size: 14px;
            background: rgb(248, 248, 248);
            color:  rgba(67, 67, 67, .5);
            width: 49px;
            border: solid 10px #ffffff;
            transition: background 150ms linear, color 150ms linear;
            position: relative;
            z-index: 2;

            &.isActive {
                background: #E3773B;// #434343;
                color: #ffffff;
            }
        }
    }
    
    &-input {
        flex: 1;
        margin-left: 2rem;

        .input {
            margin-bottom: 1rem;
        }

        .button {
            font-size: 16px !important;
            display: block;
            width: 100% !important;
        }
    }
}

.modal-v2-small {
    max-width: 450px;
}

.reg-modal-button {
    display: flex;
    justify-content: flex-end;
    
    .button {
        background: #E3773B;// #434343;
        color: #ffffff;
        width: auto !important;
        padding: 8px 30px;
        display: inline-block !important;
        height: auto !important;
    }
}

.ir {
    width: 100% !important;
}

.slide {
    display: flex !important;
    justify-content: center;

    .image {
        display: inline-block !important;
        max-width: 95%;
    }

    img {
        display: block;
        margin: 0 auto;
        max-height: 500px;
        max-width: 100%;
    }
}

.slider {
    position: relative;
    z-index: 5;

    .slick-dots {
        bottom: -45px;

        li {
            button {
                &:before {
                    display: none;
                }

                border: solid 1px #a8a8a8;
                background-color: transparent;
                border-radius: 100%;
                height: 18px;
                width: 18px;
            }

            &.slick-active {
                button {
                    border-color: #434343;
                    background-color: #434343;
                }
            }
        }
    }
}

.slider-buttons {
    background-color: #F0F0F0;
    border-radius: 6px;
    padding: 5px;
    height: 48px;
    flex-direction: row !important;
    
    .button:not(.slide-active) {
        background-color: transparent;
        box-shadow: none;
        color: #a8a8a8;
        @include for-phone-only {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
        }
        height: 38px !important;
    }

    .button.slide-active {
        height: 38px !important;
        @include for-phone-only {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
        }
    }
}

.v2-hide-mob {
    @include for-phone-only {
        display: none;
    }
}
