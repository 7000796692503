@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.sub-box {
	border-bottom: 1px solid #eae9ea;
	margin-bottom: 30px;
}

.invoices-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	h2 {
		color: #433;
		font-size: 22px;
		font-weight: 500;
	}
	&-items {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	&-item {
		margin-top: 20px;
		border-radius: 3px;
		padding: 20px;
		@include for-phone-only {
			padding: 10px 5px;
		}
		display: flex;
		border-bottom: 1px solid #eae9ea;
		transition: 0.4s linear all;
		align-items: center;
		justify-content: space-between;
		&:hover {
			background: #f0f0f0;
		}
		.pdf-icon {
			cursor: pointer;
			img {
				width: 32px;
			}
		}
		&-info {
			p {
				margin-right: 20px;
			}
			@include for-phone-only {
				margin-bottom: 5px;
			}
		}
		@include for-phone-only {
			// flex-direction: column;
		}
		strong {
			margin-right: 15px;
			min-width: 160px;
			@include for-phone-only {
				// min-width: auto;
			}
		}
	}
	&-empty {
		@include full-center();
		flex: 1;
	}

	&-change {
		> .button {
			margin-top: 15px;
			@include for-phone-only {
				width: 100%;
				text-align: center !important;
				justify-content: center;
			}
		}
		@include for-tablet-up {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			> .button {
				margin-top: -23px;
			}
		}
	}
}

.change-modal-body {
	@include for-tablet-up {
		display: flex;
	}

	> div {
		flex: 1;

		&:first-child {
			@include for-tablet-up {
				margin-right: 32px;
				padding-right: 32px;
				border-right: solid 1px $color-gray;
			}
			display: flex;
			flex-direction: column;
			align-items: space-between;
		}

		&:last-child {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&-step {
		display: flex;
		align-items: center;
		position: relative;
		padding-bottom: 36px;
		line-height: 1.2;

		span {
			margin-right: 20px;
		}

		&:after, &:before {
			@include circle(8px);
			background-color: rgb(218, 221, 207);
			content: '';
			position: absolute;
			left: 9px;
			top: 32px;
		}

		&:after {
			top: 46px;
		}		

		&:last-of-type {
			&:after, &:before {
				display: none;
			}
		}
	}

	.hide-xs {
		display: none;
		@include for-tablet-up {
			display: block;
		}
	}
	.show-xs {
		display: none;
		@include for-phone-only {
			display: block;
		}
	}

	.badge {
		@include circle(25px);
		@include full-center();
		background-color: $color-secondary;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.buttons {
		text-align: center;
		@include for-phone-only {
			margin: 16px 0 64px 0;

			.button {
				width: 100%;
				justify-content: center;
			}
		}
	}
}

.button-row-account {
	@include for-phone-only {
		display: flex;
		flex-direction: column-reverse;
		.button {
			justify-content: center;
			text-align: center;
			margin-bottom: 10px;
			width: 100%;
		}
	}
}

.account-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100%;
	.icon {
		margin-bottom: 20px;
		i {
			color: $color-primary;
			font-size: 64px;
		}
	}
	p {
		opacity: 0.6;
	}
}

.save-button {
	display: flex;
	justify-content: flex-end;
	padding: 15px 30px;
}

.restaurant-edit-view {
	.card-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}
}

.account-view {
	// margin-top: 30px;
	@include grid(2);
	@include for-tablet-under {
		grid-template-columns: repeat(1, 1fr);
	}
	.info-row {
		flex-wrap: wrap;
		.input,
		.dropdown {
			@include for-phone-only {
				width: 100%;
				margin: 0 0 20px 0;
			}
		}
	}
	.card {
		margin-top: 30px;
		&-info {
			padding: 0 20px 20px 20px;
			@include for-phone-only {
				padding: 0 20px 20px 20px;
			}
		}
		.styled-text-bold {
			margin-top: 20px;
			margin-bottom: 20px !important;
			font-size: 20px;
			color: $color-text;
		}
	}
	.input,
	.dropdown {
		margin-bottom: 20px;
	}
	.button-row {
		display: flex;
		justify-content: space-between;
	}
	.buttons {
		margin-top: 20px;
		display: flex;
		.button {
			margin-right: 20px;
			@include for-tablet-under {
				margin-bottom: 20px;
				width: 100%;
			}
		}
	}
	.reset-password-view-form {
		width: 100%;
		.input {
			margin-top: 0;
		}
	}
}

$imgSize: 135px;

.avatar {
	img {
		width: $imgSize;
		border-radius: 8px;
		height: $imgSize;
		object-fit: contain;
	}
	.file-upload-result {
		max-width: 220px;
	}
}

.restaurant-gallery {
	display: flex;
	flex-wrap: wrap;
	.image-upload {
		margin-right: 20px;
		margin-bottom: 20px;
		img {
			width: $imgSize;
			border-radius: 8px;
			height: $imgSize;
			object-fit: cover;
		}
	}
}

.url-copy {
	.input {
		margin-top: 20px;
		input {
			background-color: $color-gray;
			@include for-phone-only {
				max-width: 190px;
			}
		}
	}
	.button {
		border-radius: 0px 6px 6px 0px;
	}
}

.desktop-buttons {
	@include for-tablet-under {
		display: none !important;
	}
}

.mobile-buttons {
	display: flex !important;
	@include for-desktop-up {
		display: none !important;
	}
}
