$color-primary: #5a603e;
$color-secondary: #979f73;
$color-gray: #f5f5f5;
$color-gray-dark: #a8a8a8;
$color-text: #2d2d2d;
$color-background: #fff;
$color-background-admin: #f8f8f8;

$color-blue: #3B86FF;
$color-yellow: #E5B900;
$color-red: #C13030;

$button-height: 45px;
$input-height: 45px;
$nav-height: 90px;
$nav-height-mobile: 60px;
$shadow: 0px 3px 66px #5a603e20;

$search-box-height: 60px;
