.message {
    background-color: rgba(229, 185, 0, .06);
    color: #E5B900;
    padding: 16px;
    display: flex;
    
    &-icon {
        color: #E5B900;
        font-size: 24px;
    }
    
    &-content {
        margin-left: 16px;
        flex: 1;
        color: #E5B900;
        font-size: 14px;
        line-height: 21px;
        font-weight: 100;
    }

    &-error {
        background-color: rgba(188, 0, 0, .06);
        color: #BC0000;

        .message-icon, .message-content {
            color: #BC0000;
        }
    }
}
