@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.tp2-button {
	// margin-top: 15px;
}

.tp2-form-is-open {
	@include for-tablet-under {
		display: none !important;
	}
}

.tp2 {
	margin-top: 15px;
	display: flex;
	flex: 1;
	@include for-phone-only {
		flex-direction: column;
	}

	.item-note {
		@include for-phone-only {
			margin-top: 32px;
		}
	}

	&-button {
		max-width: 420px;
		// @include full-center();
		@include for-phone-only {
			position: sticky;
			// top: 66px;
			top: -32px;
			padding: 16px;
			background-color: #ffffff;
			z-index: 100;
			margin: 0 -10px;
			// max-width: 130%;
		}
	}
	> .buttons {
		display: flex;
	}
	.card-info {
		@include for-phone-only {
			padding: 0;
		}
	}

	.MuiFormControl-root {
		@include for-tablet-under {
			width: 100%;
		}
		@include for-phone-only {
			margin-bottom: 10px !important;
		}
	}

	&-loading {
		flex: 1;
		@include for-tablet-under {
			width: 100%;
			padding: 40px 20px;
		}
		@include full-center();
		// .button {
		// 	margin-top: 20px;
		// }
		.icon {
			margin-top: 20px;
			img {
				width: 50px;
			}
		}
	}
	h1 {
		@include for-tablet-under {
			font-size: 16px;
			vertical-align: middle;
			line-height: 16px;
		}
	}
	&-items {
		display: flex;
		flex-direction: column;
		// background: $color-gray;
		// padding: 20px;
		margin-right: 1rem;
		overflow-y: auto;
		width: 420px;
		// max-height: 600px;
		// @include for-phone-only {
		// 	display: none;
		// }
		@include for-tablet-under {
			margin-right: inherit;
			width: 100%;
		}
	}
	&-body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.type-row {
		align-items: center;
		@include for-phone-only {
			align-items: flex-start;
		}
		.custom-dropdown {
			margin-right: 10px;
		}
	}
	.type {
		@include for-phone-only {
			width: 100%;
		}
	}
	.row {
		display: flex;
		justify-content: space-between;
		@include for-phone-only {
			flex-direction: column;
		}

		.item {
			&:first-child {
				width: 250px;
			}
			&:last-child {
				flex: 1;
				max-width: 360px;
			}
		}
	}

	.date-row .row {
		justify-content: flex-start;
		@include for-phone-only {
			flex-direction: row;
			justify-content: space-between !important;
		}
	}

	&-form {
		padding: 0;
		flex: 1;
		max-width: 778px;
		box-shadow: none !important;

		&-freq {
			display: flex;

			&-option {
				display: block;
				height: 45px;
				width: 45px;
				cursor: pointer;
				border-radius: 3px;
				color: #434343;
				background: #f0f0f0;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 8px;

				&-active {
					background: #434343;
					color: #ffffff;
				}
			}
		}
		
		&-buttons {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			@include for-tablet-up {
				margin-top: -60px;
			}
			max-width: 778px;

			// > div, > a {
			.tp2l {
				@include for-phone-only {
					display: none;
				}
			}

			&-mobile {
				display: none;
				@include for-phone-only {
					display: flex;
					justify-content: flex-end;
					position: sticky;
					right: 18px;
					// margin-top: -68px;
					z-index: 101;
					position: sticky;
					// top: 76px;
					top: -16px;
					width: 100%;
				}
			}
		}

		&-head {
			display: flex;
			justify-content: space-between;
			width: 100%;
			@include for-tablet-under {
				align-items: center;
			}
			.back {
				color: #434343 !important;
				margin-right: 10px;
			}
			strong {
				vertical-align: middle;
				display: flex;
				align-items: center;
				margin: 0;
			}
			.button {
				margin-right: 15px;
				&:last-child {
					margin: 0;
				}
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			// margin-top: 20px;
			margin-bottom: 10px;
			color: $color-text;
			@include for-phone-only {
				// margin-top: 20px;
			}
		}
		&-type {
			.row {
				.item:first-child {
					@include for-phone-only {
						width: 100%;
						margin-bottom: 15px;
					}
				}

				.item:last-child {
					@include for-phone-only {
						width: 100%;
						max-width: auto;
					}
				}

				.dropdown {
					width: 150px;
					@include for-phone-only {
						width: 100% !important;
					}
				}
			}
		}

		> .card-info > div {
			margin-bottom: 50px;
			@include for-phone-only {
				margin-bottom: 20px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.dropdown {
		@include for-phone-only {
			margin-bottom: 20px;
			width: calc(100% - 20px) !important;
		}
	}
	.date-row {
		.time-row {
			background: #434343;
			height: $input-height;
			@include full-center;
			color: #ffffff;
			margin-right: 1rem;
			width: 85px;
		}

		.input {
			margin-right: 5px;
			display: flex;
			align-items: center;
			flex-direction: row;
			@include for-phone-only {
				flex-direction: column;
				align-items: flex-start;
				label {
					display: block;
				}
				input {
					margin-top: 5px;
				}
			}
			label {
				margin: 0 5px 0 0;
				@include for-phone-only {
					margin: 0 5px 0 0;
				}
			}
		}
	}
}

.t-days {
	display: flex;
	justify-content: space-between;
	@include for-tablet-under {
		// margin-top: 20px;
	}
	@include for-phone-only {
		margin-top: 0px;
	}
	&-primary {
		.day {
			background: $color-blue !important;
			color: #fff;
		}
	}
	.day {
		margin-right: 10px;
		&:last-child {
			margin: 0;
		}
		// border: 1px solid $color-gray-dark;
		background: $color-blue;
		@include full-center();
		color: #fff;
		padding: 5px;
		border-radius: 3px;
		cursor: pointer;
		opacity: 0.4;
		width: 40px;
		height: 40px;
		font-weight: 600;
		@include for-tablet-under {
			width: 35px;
			height: 35px;
		}

		@include for-phone-only {
			padding: 0;
			font-size: 14px;
		}

		&-active {
			opacity: 1 !important;
		}
	}
}

.timeslot-button {
	&:after {
		content: '';
		display: block;
		margin-top: 20px;
		border-bottom: 1px solid #F0F0F0;
	}
	// margin-bottom: 20px;

	padding: 20px 20px 0 20px;
	cursor: pointer;
	text-decoration: none;
	color: #000;
	&-active {
		background-color: $color-primary;
		padding-bottom: 20px;
		&:after {
			display: none;
		}
		.day {
			border-color: #fff;
			background: #fff;
			color: $color-gray-dark;
		}
		.timeslot-button-info {
			p {
				color: #fff;
			}
		}
	}
	&-date {
		border: 1px solid darken($color-gray, 10);
		position: relative;
		background: $color-gray-dark;
		color: #fff;
		border-radius: 7px;
		height: 40px;
		@include full-center();
		p {
			font-weight: 600;
			color: #fff;
		}
		.icon {
			position: absolute;
			left: 10px;
			display: flex;
			align-items: center;
			top: 0;
			bottom: 0;
			img {
				height: 22px;
			}
		}
	}
	&-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0 0 0;
	}
}

.tp2-choice-modal {
	.choice-row {
		display: flex;
		flex-wrap: wrap;
		.col {
			flex: 1;
			@include full-center();
			justify-content: flex-start;
		}
		img {
			max-height: 180px;
		}
		.styled-text {
			text-align: center;
			max-width: 80%;
			flex: 1;
			margin: 10px 0;
		}
	}
}
