@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.checkbox {
	width: 24px;
	height: 24px;
	@include full-center();
	border: 1px solid #EAE9EA;
	border-radius: 3px;
	cursor: pointer;
	transition: 0.4s ease all;
	
	&:hover {
		border-color: #989E76;
		background-color: rgba(151, 159, 115, .2);
	}

	&-checked {
		border-color: #989E76;
		background-color: #989E76 !important;
		i {
			opacity: 1 !important;
			color: #ffffff;
		}
	}

	i {
		opacity: 0;
		color: $color-gray;
		transition: 0.4s ease all;
		position: relative;
		top: 1px;
	}
	&-wrapper {
		display: flex;
		align-items: center;
	}
	&-label {
		cursor: pointer;
		font-size: 14px;
		margin-left: 24px;
		font-family: 'Poppins', sans-serif;
		font-weight: 100;
	}
}
