@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.suggestions {
}

.card {
	border-radius: 3px;
	background-color: #ffffff; //$color-background;
	// box-shadow: 0px 3px 66px #5a603e20;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

	&-no-shadow {
		box-shadow: 0px 0px 0px transparent;
	}
	&-no-padding {
		.card-info {
			padding: 0 !important;
		}
	}
	&-has-slideshow {
		.slide-show {
			height: 200px;
			border-radius: 3px 3px 0 0;
			img {
				border-radius: 3px 3px 0 0;

				height: 200px;
				object-fit: cover;
			}
		}
	}

	&-thumbnail {
		border-radius: 3px 3px 0 0;
		width: 100%;
		height: 200px;
		background-position: center;
		background-size: cover;
		background-color: $color-primary;
		@include full-center();
		&-title {
			color: #fff !important;
		}
	}

	&-loading,
	&-info {
		padding: 40px;
	}
	&-loading {
		@include full-center();
		i {
			font-size: 2em;
			color: $color-primary;
		}
	}
	&-primary {
		color: $color-background;
		background-color: $color-primary;
		h1,
		p,
		b {
			color: $color-background;
		}
	}
	&-center {
		@include full-center();
	}
	h1 {
		font-size: 26px;
	}
}
