@import "../../../../styles/mixins.scss";
@import "../../../../styles/vars.scss";

.pause-timeslot-view {
	margin-top: 15px;
	display: flex;
	flex: 1;
	@include for-phone-only {
		flex-direction: column;
	}

	&-is-open {
		.pause-timeslot-view-list {
			@include for-tablet-under {
				display: none !important;
			}
		}
		.pause-timeslot-view-form {
			display: flex !important;
			max-width: 778px;
			@include for-phone-only {
				padding: 0;
			}
		}
	}
	&-button {
		max-width: 420px;
		margin-top: 15px;
		// @include full-center();
	}
	&-list {
		display: flex;
		flex-direction: column;
		// background: $color-gray;
		// padding: 20px;
		overflow-y: auto;
		width: 420px;
		margin-right: 1rem;
		height: calc(100vh - 323px);
		@include for-tablet-under {
			width: 100%;
			max-height: calc(100vh - 240px);
		}
	}
	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;
		position: sticky;
		left: 0;
		right: 0;
		background: #fff;
		top: 0;
		z-index: 88;
		.button {
			margin-left: 20px;
		}
	}
	&-form {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding: 0 0 20px 0;
		max-height: calc(100vh - 323px);
		// overflow-y: scroll;
		position: relative;
		@include for-tablet-under {
			display: none;
		}
		form {
			padding: 0 40px;
			position: relative;
		}
		h2 {
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 10px;
			color: $color-text;
			@include for-phone-only {
				margin-top: 20px;
			}
		}
		.back {
			margin-left: 0 !important;
			cursor: pointer;
		}
		.max {
			max-width: 200px;
		}
		.i .dropdown {
			width: 100%;
			max-width: 240px;
		}
		.form-row,
		.form-group {
			margin-bottom: 30px;
		}

		.form-group {
			width: 100%;
			&:first-child {
				margin-top: 20px;
			}
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			.form-group {
				margin-right: 20px;
				&:last-child {
					margin: 0;
					// width: max-content;
				}
			}
		}
		textarea {
			max-height: 200px;
		}
		.form-badge {
			padding: 0 30px;
			cursor: pointer;
			// text-transform: uppercase;
			height: $button-height;
			background: #a8a8a8;
			width: max-content;
			border-radius: 7px;
			font-size: 20px;
			@include full-center();
		}
	}
}
