.mw-calendar {
    border: 0;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    width: auto;

    .react-calendar__navigation button { // top nav
        background-color: transparent !important;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        color: #979F73;
        font-weight: 500;

        &:hover {
            background-color: transparent;
        }

        &:disabled {
            background-color: transparent;
            opacity: .5;
        }
    }

    .react-calendar__month-view__weekdays { // weekdays
        color: #828282;
        font-weight: 500;
        text-transform: lowercase;
        text-decoration: none;

        abbr {
            border-bottom: 0;
            text-decoration: none;
        }
    }

    .react-calendar__tile { // day - tile
        border-radius: 4px;
        color: #434343;
        font-weight: 100;
        transition: background-color 150ms linear, color 150ms linear;

        &:hover {
            background-color: #979F73;
            color: #ffffff;
        }

        &--active {
            background-color: #5A603E;
            color: #ffffff;
        }

        &:disabled {
            background-color: transparent;
            opacity: .3;
            color: #434343;
        }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #a8a8a8;
    }

    &-large {
        @media screen and (max-width: 992px) {
            .options {
                display: none;
            }

            .react-calendar__navigation {
                position: fixed;
                top: 0;
                z-index: 3000;
                background: #ffffff;
                height: 65px;
                left: 0;
                min-width: 200px;
                opacity: 1;
                transition: opacity 150ms linear;

                button {
                    color: #434343;
                }
            }

            .react-calendar__month-view__weekdays {
                font-weight: 100;
                padding-bottom: 8px;
                font-size: 14px;
            }

            .react-calendar__tile {
                background-color: transparent !important;
                padding: 0 0 10px 0;
                position: relative;

                > abbr {
                    border-radius: 4px;
                    display: block;
                    height: 100%;
                    width: 90%;
                    margin: 0 auto;
                    padding: 6px 4px;
                    font-size: 14px;
                    font-weight: 700;
                }

                &--now {
                    > abbr {
                        background-color: rgba(151, 159, 115, .15) !important;
                        color: #434343 !important;
                    }
                }

                &:hover {
                    > abbr {
                        background-color: #5A603E !important;
                        color: #ffffff !important;
                    }
                }
        
                &--active {
                    > abbr {
                        background-color: #5A603E !important;
                        color: #ffffff !important;
                    }
                }
            }
        }

        @media screen and (min-width: 992px) {
            background-color: transparent;
            // border-left: solid 1px #f0f0f0;
            // border-bottom: solid 1px #f0f0f0;
            position: relative;

            .react-calendar__navigation {
                position: absolute;
                top: -76px;
                z-index: 3000;
                // left: -16px;
                left: 460px;
                // width: 300px;
                opacity: 1;
                transition: opacity 150ms linear;

                button {
                    color: #434343;
                    text-transform: capitalize;
                }

                .react-calendar__navigation__arrow {
                    height: 45px;
                    width: 45px;
                    background-color: transparent !important;
                    border-radius: 3px;
                    transition: background-color 150ms linear;
                    color: #a8a8a8 !important;

                    &:hover {
                        background-color: rgba(168, 168, 168, .15) !important;
                    }

                    &.react-calendar__navigation__prev-button {
                        margin-right: 30px;
                    }
                    &.react-calendar__navigation__next-button {
                        margin-left: 30px;
                    }
                }
            }

            .react-calendar__tile {
                align-items: flex-start;
                display: flex;
                border-right: solid 1px #f0f0f0;
                border-top: solid 1px #f0f0f0;
                justify-content: flex-end;
                font-size: 12px;
                overflow: visible !important;
                @media screen and (min-width: 1024px) {
                    font-size: 14px;
                    font-weight: 100;
                    font-family: 'Poppins', sans-serif;
                    color: #434343;
                }
                border-radius: 0;
                position: relative;

                &:before {
                    content: '';
                    padding-bottom: 100%;
                }

                &--now {
                    background-color: rgba(151, 159, 115, .15) !important;
                    color: #434343 !important;
                }

                .options {
                    display: none;
                    font-size: 12px;
                    padding: 0;
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    height: 32px;
                    width: 32px;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    border: solid 1px #f0f0f0;
                    z-index: 99;
                    i {
                        color: #a8a8a8;
                    }

                    &:hover {
                        background-color: #ffffff;
                        display: flex;
                    }

                    > div {
                        top: 40px;
                    }

                    .button-menu {
                        &-left {
                            right: auto;
                            left: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 150px;
                            top: -10px;
                            height: 10px;
                            right: 0;
                        }
                    }
                }

                &:hover {
                    background-color: #979F73 !important;
                    color: #ffffff !important;

                    .options {
                        display: flex;
                    }
                }
        
                &--active {
                    background-color: #ebf8fb !important;
                    color: #434343 !important;
                }
            }

            .react-calendar__month-view__weekdays {
                border: solid 1px #f0f0f0;
                margin-bottom: 2px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                background-color: #ffffff;
            }

            .react-calendar__month-view__days {
                border-left: solid 1px #f0f0f0;
                border-bottom: solid 1px #f0f0f0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                background-color: #ffffff;
            }

            .react-calendar__month-view__weekdays__weekday {
                font-weight: 100;
                font-family: 'Poppins', sans-serif;
                color: #434343;
                font-size: 14px;
                text-transform: capitalize;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

body.sidebar-open .react-calendar__navigation {
    opacity: 0;
    transition-delay: 0s;
}
