@import "../../../../styles/mixins.scss";
@import "../../../../styles/vars.scss";

.mobile-reservation-item-actions.toggled {
	pointer-events: all !important;
	transform: translateX(0);
}

.mobile-reservation-item {
	border-left-width: 4px;
	border-left-style: solid;
	border-radius: 5px;
	background: #f0f0f0;
	margin-bottom: 10px;
	padding: 0 10px;
	position: relative;
	overflow: hidden;
	&:last-child {
		margin-bottom: 100px;
	}
	.row {
		flex: 1;
		display: flex;
		align-items: center;
	}
	p,
	span {
		font-weight: 300;
	}

	&-head {
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
		// border-bottom: 1px solid #cdcdcd;
		font-size: 14px;
		align-items: center;
		vertical-align: middle;
		.status {
			text-transform: uppercase;
		}
	}
	&-body {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.image {
			height: 25px;
			margin-right: 15px;
			img {
				height: 25px;
			}
		}
		.more-button {
			background: #434343;
			cursor: pointer;
			color: #fff;
			height: 22px;
			@include full-center();
			font-size: 12px;
			font-weight: 300;
			padding: 0 15px;
			border-radius: 5px;
			border-top: 3px solid transparent;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-bottom: 3px solid rgba(0, 0, 0, 0.4);
		}
	}
	&-actions {
		transform: translateX(-100vw);
		transition: 0.4s linear all;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 92px;
		border-radius: 5px;
		background: rgba(#f0f0f0, 0.9);
		position: absolute;
		z-index: 88;
		left: 0;
		right: 0;
		top: 0;
		.action {
			flex: 1;
			margin: 10px 0;
			@include full-center();
			.close-button {
				flex: 1;
				@include full-center();
				img {
					height: 25px;
				}
			}
			.icon {
				background: #5a5f3d;
				color: #fff;
				cursor: pointer;
				height: 45px;
				width: 45px;
				@include full-center();
				border-radius: 4px;
				transition: 0.4s ease all;
				&:hover {
					background: #979f73;
				}
				img {
					height: 22px;
				}
			}
		}
	}
}

.person-count {
	display: flex;
	height: 25px;
	background: #f8f8f8;
	border-radius: 5px;
	width: 60px;
	justify-content: space-between;
	align-items: center;
	margin-right: 15px;
	&-width {
		width: max-content;
		p {
			padding: 0 10px;
		}
	}

	.image {
		width: 32px;
		@include full-center();
	}
	p {
		flex: 1;
		text-align: center;
		font-weight: 300;
		color: #434343;
	}
}

$line-size: 1px;
.multi-line {
	display: block;
	&-horizontal {
		border-top-width: $line-size;
		border-top-style: solid;
		padding-top: $line-size;
		width: 100%;
	}
	&-vertical {
		border-left-width: $line-size;
		border-left-style: solid;
		display: block;
		padding-left: $line-size;
	}
}

.trunc {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	@include for-phone-only {
		width: 160px;
	}
}
