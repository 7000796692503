@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.settings-view {
	.settings-header {
		background-color: #ffffff;
		color: rgba(67, 67, 67, .4);
		font-family: "Poppins", sans-serif;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 500;
		margin: 16px -16px 8px -16px;
		padding: 16px 16px 0 16px;
		position: sticky;
		top: 66px;
		z-index: 2;

		&:first-of-type {
			margin-top: -16px;
			padding-top: 16px;
		}
	}

	.settings-tab {
		align-items: center;
		display: flex;
		height: 48px;
		text-decoration: none;

		> div {
			align-items: center;
			display: flex;
			height: 42px;
			justify-content: center;
			margin-left: -10px;
			width: 46px;
		}

		i {
			color: #434343;
			font-size: 16px;
		}

		> i:last-child {
			color: rgba(67, 67, 67, .4);
			margin-left: 20px;
		}

		span {
			color: #434343;
			font-family: "Poppins", sans-serif;
			font-size: 16px;
			font-weight: 100;
			flex: 1;
		}
	}
}

.settings-page {
	background-color: #ffffff;
	height: calc(100vh - 66px - 90px);
	left: 0;
	overflow: auto;
	padding: 32px 20px;
	position: fixed;
	transform: translateX(100%);
	box-shadow: 0;
	transition: transform 200ms cubic-bezier(0.37, 0, 0.63, 1), box-shadow 150ms linear;
	top: 66px;
	width: 100%;
	z-index: 2;

	&-show {
		transform: translateX(0);
		box-shadow: 0 0 20px rgba(0, 0, 0, .2);
	}
}

.sv-switch {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	justify-content: space-between;

	.switch-label {
		flex: 1;
		margin-right: 32px;
		font-size: 16px;
		line-height: 25px;
		font-weight: 100;
		color: #434343;
	}
}

.sv-description {
	color: #A8A8A8;
	font-size: 14px;
	line-height: 20px;
	font-weight: 100;
	margin-bottom: 32px;
}

.sv-subtitle {
	color: #434343;
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	margin-bottom: 16px;
}

.settings-full {
	margin-top: 26px;

	.sf-title {
		font-size: 20px;
		color: #434343;
		font-weight: 500;
		font-family: 'Poppins', sans-serif;
		border-bottom: solid 1px #EAE9EA;
		padding-bottom: 12px;
		margin-bottom: 12px;
	}

	.sf-row {
		background-color: #ffffff;
		border-radius: 3px;
		padding: 20px;
		transition: background-color 150ms linear;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;

		font-size: 16px;
		color: #434343;
		line-height: 25px;

		&-label {
			font-weight: 100;
			width: 240px;
			margin-right: 32px;
		}

		&-description {
			color: #a8a8a8;
			font-weight: 100;
			margin-right: 64px;
			flex: 1;
			transition: color 150ms linear;

			.sf-rd-input {
				display: flex;
				align-items: center;
				margin-top: 16px;

				button {
					margin-left: 16px;
				}
			}
		}

		&-action {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			min-width: 250px;

			button + *,
			a + * {
				margin-left: 30px;
			}
		}

		&:hover {
			background-color: rgba(240, 240, 240, .45);

			.sf-row-label, button, a {
				font-weight: 500;
			}

			.sf-row-description {
				color: #434343;
			}
		}

		&--expected {
			background-color: #ffffff !important;
			color: rgba(59, 134, 255, .5);
			font-weight: 100;
			.sf-row-label {
				font-weight: 100 !important;
			}

			span {
				background-color: rgba(59, 134, 255, .1);
				color: rgba(59, 134, 255, 1);
				display: inline-block;
				padding: 10px 14px;
				font-size: 16px;
				border-radius: 3px;
				text-transform: uppercase;
				font-weight: 500 !important;
			}
		}
	}
}

.fsq-search {
	align-items: center;
	display: flex;
	background-color: #ffffff;
	border-radius: 3px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
	position: relative;
	height: 42px;
	margin-bottom: 16px;
	z-index: 10;

	> i:first-child {
		color: #434343;
		position: absolute;
		top: 50%;
		font-size: 16px;
		transform: translateY(-50%);
		left: 16px;
	}

	> input {
		border: 0;
		padding-left: 48px;
		width: auto;
		display: block;
	}
}

.fsq-filter-rule {
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
	padding: 16px;
	position: relative;
	margin-bottom: 8px;

	button {
		background-color: transparent !important;
		box-shadow: none !important;
		position: absolute;
		top: 8px;
		right: 0;
	}

	> div:first-child {
		// color: #A8A8A8;
		font-size: 14px;
		line-height: 20px;
		font-weight: 100;
		margin-bottom: 8px;
		display: flex;
		align-items: center;

		> div {
			margin: 0 10px;
			width: 120px;
		}
	}
}
