@import "../../styles/vars.scss";

.switch-wrapper {
	display: flex;
	align-items: center;

	&.sw-full {
		align-items: flex-start;
		justify-content: space-between;
	}
}

.switch-label {
	margin-right: 10px;
}
.sw-full .switch-label {
	margin-right: 48px;
}

.switch-off.switch-active {
	background: #c0260e !important;
}
.switch {
	border-radius: 4px;
	border: 2px solid $color-gray;
	box-sizing: content-box;
	display: flex;

	&-item {
		display: block;
		padding: 1px 10px;
		cursor: pointer;
		border-radius: 4px;
		text-transform: uppercase;
		font-size: 14px;
	}
	&-active {
		background: $color-primary;
		color: #fff;
	}
}


.sw-full + .sw-full {
	padding-top: 16px;
	margin-top: 16px;
	border-top: solid 1px rgb(231, 232, 233);
}


.switch-v2 {
	background-color: #f0f0f0;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
	border-radius: 3px;
	cursor: pointer;
	display: inline-flex;
	height: 26px;
	position: relative;

	> div {
		align-items: center;
		display: flex;
		border-radius: 3px;
		flex: 1;
		height: 26px;
		padding: 0 12px;
		font-size: 12px;
		line-height: 12px;
		color: #434343;
		text-transform: uppercase;
		transition: color 150ms linear;
		position: relative;
		z-index: 2;

		&.isSelected {
			color: #ffffff;
		}
	}

	span {
		background-color: #5a603e;
		position: absolute;
		align-items: center;
		display: flex;
		border-radius: 3px;
		flex: 1;
		height: 26px;
		padding: 0 12px;
		font-size: 12px;
		line-height: 12px;
		color: rgba(0, 0, 0, 0);
		text-transform: uppercase;
		transform: translateX(0);
		transition: background 150ms linear;//, transform 150ms linear;
		z-index: 1;

		&.off {
			background-color: #bc0000;
			transform: translateX(100%);
			margin-left: 6px;
		}
	}
}
