@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.subscription-box {
	margin: 30px 0;
	.partner-name {
		font-size: 20px;
		.name {
			font-weight: 600;
			color: $color-primary;
		}
		.partner {
			color: $color-gray-dark;
		}
	}
	.info {
		display: flex;
		// @include for-tablet-under {
			flex-direction: column;
		// }
		.status {
			margin-bottom: 5px;
		}
		&-box {
			flex: 1;
			margin-top: 10px;
			margin-bottom: 24px;
			p {
				display: block;
				// max-width: 500px;
			}
		}
		&-buttons {
			display: flex;
			align-items: center;

			> button, > a {
				margin: 0 !important;
				&:first-child { margin-right: 8px !important; }
				&:last-child { margin-left: 8px !important; }
			}

			// @include full-center();
			// @include for-tablet-under {
			// 	align-items: flex-start;
			// 	flex-direction: row;
			// 	justify-content: space-between;
			// 	// padding: 20px 0;
			// 	.button {
			// 		flex: 1;
			// 		&:first-child { margin-right: 5px; }
			// 		&:last-child { margin-left: 5px; }
			// 	}
			// }
			// .button {
			// 	margin-bottom: 20px;
			// 	min-width: 160px;
			// 	text-align: center;
			// 	@include full-center();
			// 	&:last-child {
			// 		margin: 0;
			// 	}
			// }
		}
	}

	.modal-large {
		width: 1150px !important;
	}
}
