@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.reservation-detail-modal {
	display: flex;
	flex: 1;
	padding: 30px 0;
	@include for-phone-only {
		display: block;
	}
	label,
	.label {
		color: $color-primary;
		font-weight: 500 !important;
	}
	.user-info {
		width: 360px;
		padding: 0 50px 0 20px;
		justify-content: space-between;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		border-right: 1px solid darken($color-gray, 10);
		@include for-phone-only {
			border: 0;
			padding: 0;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-gray;
			width: auto;
		}
		label {
			color: $color-primary;
		}
		.styled-text {
			margin: 0 0 5px 0;
			display: block;
		}

		.button:not(.button-grey-shadow) {
			display: flex;
			justify-content: center;
			margin: 10px 0 0 0 !important;
			width: 225px;

			i {
				display: none;
			}
		}

		.button-light {
			opacity: .5;
			@include for-phone-only {
				opacity: 1;
			}

			&:hover {
				opacity: 1;
			}
		}

		.buttons {
			padding-top: 50px;
			.buttons-toggle {
				display: none;
			}

			@include for-phone-only {
				background: #F0F0F0;
				padding: 60px 32px 50px 32px;
				margin: 0 -32px;
				position: fixed;
				z-index: 2000;
				bottom: -280px;
				width: 100vw;
				height: 330px;
				transition: bottom 200ms ease-in-out;
				display: flex;
				flex-direction: column;
				align-items: center;

				&.show {
					bottom: 0;
				}

				> .button {
					box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
					border: 0;
					background-color: #ffffff;
					color: #434343;
					font-weight: 500;
					font-size: 1rem;
					padding-left: 1rem;
					padding-right: 1rem;
					position: relative;
					width: 80% !important;

					i {
						display: block;
						position: absolute;
						left: 24px;
					}

					&:hover {
						background-color: rgba(90, 96, 62, .01);
					}
				}

				.buttons-toggle {
					display: block;
					position: absolute;
					top: -29px;
					transform: translateX(-50%);
					left: 50%;

					.button {
						opacity: 1 !important; 
						width: 100%;
					}
				}
			}
		}
	}
	.info {
		padding: 0 30px;
		width: 100%;
		@include for-phone-only {
			padding: 0;
		}
		.text {
			b, strong { color: #5A603E; }
			margin-bottom: 24px;
		}

		&-part {
			padding: 10px 0;
			border-bottom: 1px solid $color-gray;
			&-no-border {
				border-bottom: 0px !important;
			}
			&-block {
				.styled-text {
					display: block;
				}
			}

			@include for-phone-only {
				display: flex;
				flex-direction: column;

				.row {
					order: 2;
				}

				.row:last-child {
					order: 1;
					margin: 0 0 15px 0;
				}
			}
		}
		.row {
			display: flex;
			justify-content: space-between;
			margin-top: 3px;
			&-justify {
				label {
					text-align: left !important;
				}
			}
			label {
				text-align: right;
				padding-right: 10px;
			}
			.styled-text {
				flex: 1;
			}

			.tag {
				flex: 1;

				&:first-child {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
		.badge {
			border-radius: 5px;
		}
		.person-count {
			height: 30px;
		}
		.cta-buttons {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			@include for-phone-only {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				// padding-bottom: 20px;
				background: #484b38;
				.button {
					transform: translateY(-22.5px);
				}
			}
			.button {
				margin-right: 15px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.status-warning {
	background: rgba(229, 185, 0, .06);
	border-radius: 3px;
	display: flex;
	padding: 10px 1.5rem;
	align-items: center;

	@include for-phone-only {
		display: none;
	}

	&-icon {
		color: #E5B900;
		font-size: 32px;
		margin-right: 24px;
	}

	&-status {
		display: flex;
		flex: 1;

		.row-status {
			background-color: #E5B900;
			color: #ffffff;
			margin-right: 10px;
		}
	}

	&-info {
		cursor: pointer;
	}
}

.rdm-info {
	.user-info {
		width: auto;
	}

	.status-warning {
		align-items: flex-start;
	}

	.rs {
		padding: 16px;
		span {
			margin: 0 10px 10px 0;
		}
	}
}
