@import "../../styles/vars.scss";

.button {
	background: $color-primary;
	color: $color-background;
	font-weight: 300;
	padding: 0 30px;
	cursor: pointer;
	// text-transform: uppercase;
	height: $button-height;
	// border-radius: 40px;
	border-radius: 5px;
	border-top: 3px solid transparent;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-bottom: 3px solid rgba(0, 0, 0, 0.4);
	font-size: 14px;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.4s ease-in-out all;
	text-decoration: none;
	text-align: center;

	box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
	border: 0;
	border-radius: 3px;
	font-weight: 500;
	font-size: 1rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;

	&-no-uppercase {
		text-transform: none;
		font-weight: 300;
	}
	&-flip-icon {
		i {
			transform: rotate(90deg);
		}
	}
	&-is-svg {
		padding: 0 15px;
	}
	img {
		width: 22px;
		height: 22px;
		object-fit: contain;
	}
	&-empty {
		i {
			margin: 0 !important;
		}
	}

	&:hover {
		border-bottom-color: rgba(0, 0, 0, 0.1);
		opacity: 0.9;
	}
	&-outline {
		background-color: transparent;
		border: 2px solid $color-primary;
		color: $color-primary;
		&:hover {
			background: $color-primary;
			border-bottom-color: $color-primary;
			color: #fff;
		}
	}
	&-disabled {
		pointer-events: none;
		opacity: 0.4;
	}
	&-loading {
		pointer-events: none;
		opacity: 0.8;
	}
	&-gray {
		color: $color-text;
		background-color: $color-gray;
		border-bottom-color: rgba(0, 0, 0, 0.1);
	}
	&-white {
		background: $color-background;
		color: $color-text;
		border-bottom-color: rgba(0, 0, 0, 0.1);
	}
	&-transparent {
		background: transparent;
		padding: 0;
		border-bottom-color: transparent;
		color: $color-gray-dark;
		box-shadow: none;
		&:hover {
			border-bottom-color: transparent !important;
		}
	}
	&-secondary {
		background: $color-secondary;
		border-bottom-color: rgba(0, 0, 0, 0.2);
		// &:hover {
		// 	border-bottom-color: transparent !important;
		// }
	}
	&-icon-left {
		i {
			margin-right: 10px;
			display: inline-block;
		}
	}
	&-icon-right {
		i {
			margin-left: 10px;
			display: inline-block;
		}
	}

	span + i {
		margin-left: 1rem;
	}

	i + span {
		margin-left: 1rem;
	}

	&-white-shadow, &-white, &-grey-shadow {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
		border: 0;
		background-color: #ffffff;
		color: #434343;
		font-weight: 500;
		font-size: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;

		&:hover {
			background-color: rgba(90, 96, 62, .01);
		}
	}
	&-white {
		box-shadow: none;
	}

	&-grey-shadow {
		background-color: #f8f8f8;
	}

	&-green-shadow {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
		border: 0;
		font-weight: 500;
		font-size: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;

		i {
			margin-left: 1rem;
		}
	}

	&-is-delete {
		color: #BC0000;
	}

	&-light {
		font-weight: 300;
	}

	&-has-menu {
		// color: #5A603E;
		position: relative;
		opacity: 1 !important;

		> i {
			font-size: 22px;
		}

		&.button-disabled {
			pointer-events: none;
			opacity: 0.4 !important;
		}
	}

	&-menu {
		background: #ffffff;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
		position: absolute;
		top: 50px;
		right: 0;
		padding: .5rem;
		border-radius: 3px;
		min-width: 186px;
		font-size: 1rem !important;
		font-weight: 300 !important;
		text-align: left !important;
		color: #000000 !important;

		&-left {
			right: auto;
			left: 0;
		}

		> a {
			align-items: center;
			border-radius: 3px;
			display: flex;
			height: 36px;
			padding: 0 .5rem;
			transition: background 150ms linear;
			white-space: nowrap;
			font-weight: 300 !important;
			text-align: left !important;
			color: #000000 !important;
			text-decoration: none !important;

			i {
				font-size: 16px;
			}

			span {
				margin-left: 1rem;
				flex: 1;
			}

			&:hover {
				background: #F0F0F0;
			}
		}
	}
}

.button + .button {
	margin-left: 1rem;
}
