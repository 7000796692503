@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.loading {
	@include full-center();
	flex: 1;
	i {
		font-size: 32px;
		margin-bottom: 20px;
	}
	p {
		opacity: 0.2;
	}
}

.loading-absolute {
	background: rgba(255, 255, 255, .7);
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	z-index: 2000;
}
